import React from 'react';
import styles from './Wrapper.module.scss';

const Wrapper = ({ children, className }:{children:any, className?:string}) => (
  <div className={styles.container}>
    <div className={`${styles.wrapper} ${className}`}>{children}</div>
  </div>
);

export default Wrapper;
