import React, { useEffect, useState } from 'react';
import Wrapper from '@/components/TokenSale/Wrapper';
import { _getPartners } from '@/service/service';
import { IPartnerSlider } from '@/types/Sliders/partners';
import PartnerSlider from '../PartnersSliderNew/PartnersSlider';

const CompanyPartnersLayout = () => {
  const [partners, setPartners] = useState<IPartnerSlider>();

  useEffect(() => {
    _getPartners().then((res) => {
      setPartners(res);
    });
  }, []);

  return (
    <Wrapper>
      <PartnerSlider items={partners} />
    </Wrapper>
  );
};

export default CompanyPartnersLayout;
