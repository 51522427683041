/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable import/order */
import cn from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { Swiper, SwiperSlide } from 'swiper/react';
import { IPartnerSlider } from '@/types/Sliders/partners';
import { Autoplay, Grid } from 'swiper/modules';
import styles from './PartnersSliderNew.module.scss';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/grid';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import useTranslationStatus from '@/hooks/useTranslationStatus';
import useResponsive from '@/hooks/useResponsive';

interface IPartnerProps {
  items: IPartnerSlider;
}

const PartnerSlider = (props: IPartnerProps) => {
  const { t } = useTranslation('home');
  const { isReady } = useTranslationStatus();
  const { items } = props;
  const { isTablet, isMobile } = useResponsive();
  const router = useRouter();
  const currentLanguage = router.locale;

  const titleClass = classNames({
    [styles.parentTitle]: currentLanguage === 'en',
    [styles.parentTitle_fr]: currentLanguage !== 'en',
  });

  return (
    <div className={cn(styles.wrapper, 'partners')}>
      {items && (
        <>
          <div className={styles.title}>
            <h2 className={titleClass}>{t('home:general.company_partners')}</h2>
          </div>
          <Swiper
            grid={{
              rows: 2,
            }}
            modules={[Grid, Autoplay]}
            navigation={false}
            loop={true}
            speed={6000}
            autoplay={{ delay: 1, disableOnInteraction: false }}
            slidesPerView={'auto'}
            className={styles.slider}
            updateOnWindowResize={true}
            style={{ height: '240px', paddingTop: '10px' }}
            breakpoints={{
              300: {
                slidesPerView: 2.2,
                slidesPerGroup: 2,
                spaceBetween: 10,
              },
              380: {
                slidesPerView: 2.5,
                slidesPerGroup: 2,
                spaceBetween: 20,
              },
              500: {
                slidesPerView: 3.4,
                slidesPerGroup: 3,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 5.5,
                slidesPerGroup: 5,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 5.5,
                slidesPerGroup: 5,
                spaceBetween: 10,
              },
              1280: {
                slidesPerView: 6.5,
                slidesPerGroup: 6,
                spaceBetween: 10,
              },
              1440: {
                slidesPerView: 6.5,
                slidesPerGroup: 6,
                spaceBetween: 30,
              },
            }}
          >
            {items.map((data, index) => (
              <SwiperSlide key={index} className={cn(styles.slide, 'no-select flex h-[100px] items-center justify-center')}>
                <Link href={data.link !== null ? data.link : 'https://landrocker.io'}>
                  <a target='_blank'>
                    <Image
                      src={data?.partners?.url ? 'https://portal-admin.landrocker.io' + data?.partners?.url : '/images/NFT.svg'}
                      alt={data?.partners?.name}
                      width={isTablet ? 80 : 100}
                      height={isTablet ? 80 : 100}
                      placeholder='blur'
                      blurDataURL='/images/NFT.svg'
                      loading={'lazy'}
                      style={{ transition: 'all 1s' }}
                      className={''}
                    />
                  </a>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </>
      )}
    </div>
  );
};

export default PartnerSlider;
