import React, { ReactNode } from 'react';
import { ActionIcon, Badge, Button, Card, Group, Image, MantineProvider, Menu, Text } from '@mantine/core';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { Typography } from '@/components/Atoms';
import styles from './customCard.module.scss';
import Bullet from '@/components/Atoms/Bullet';

interface IProps {
  onClick?: () => void;
  style?: any;
  // image?: string;
  title?: string;
  bulletData?: string[];
  headerTitle?: string;
  headerLeftIcon?: ReactNode;
  isHeader?: boolean;
  isShowMore?: string;
  showMoreLink?: string;
  showMoreColor?: string;
  isMultiLine?: boolean;
  isBulletData?: boolean;
  cover?: boolean;
  content?: string;
  contentColor?: string;
  maxH?: number;
  isContentReverse?: boolean;
  titleColor?: string;
  titleAlign?: 'center' | 'left' | 'right';
  aspectRatio?: '16/9' | '2/1' | string;
  /**
   * Optional className
   */
  children?: ReactNode;
  // descriptions: string | string[] | ReactNode[];
  // href: string;
  // color?: string;
  // buttonLabel?: string;
  additionalClassName?: string;
  // isFullImage?: boolean;
  // disabledButton?: boolean;
  // border?: 'default';
  // icon?: string | ReactNode;
}

// Type guard to check if the value is a ReactNode
const isReactNode = (value: any): value is ReactNode => React.isValidElement(value) || typeof value !== 'string';

const CustomCard: React.FC<IProps> = (props) => {
  const {
    onClick,
    bulletData,
    headerTitle = '',
    isShowMore = '',
    title = '',
    content = ``,
    additionalClassName,
    headerLeftIcon,
    isHeader = false,
    maxH = 50,
    isMultiLine = false,
    titleColor = '#FF6F1E',
    aspectRatio = '16/9',
    contentColor,
    showMoreColor,
    titleAlign,
    cover,
    isContentReverse,
    isBulletData,
    showMoreLink = '/',
    children,
    style,
  } = props;
  const temp1 = 'p-[16px] pb-[24px] md:pb-[32px] lg:pb-[40px]';
  const temp2 = 'p-[16px]';
  const temp3 = 'rounded-[10px] md:rounded-[16px] xl:rounded-[20px]';
  const router = useRouter();
  return (
    <div className={`bg-[#1B1C22] ${temp3} ${additionalClassName}`} onClick={onClick !== undefined && onClick} style={style}>
      {isHeader && (
        <div className={`flex justify-between items-center ${temp2}`}>
          <div className='flex gap-2 w-[70%]'>
            {headerLeftIcon}
            {headerTitle && (
              <Typography
                // eslint-disable-next-line sonarjs/no-duplicate-string
                style={{ whiteSpace: 'normal', overflowWrap: 'break-word' }}
                isBold
                Component='div'
                className='max-w-[70%] line-clamp-1'
                size='s16ToS14'
              >
                {headerTitle}
              </Typography>
            )}
          </div>
          {isShowMore && (
            <Typography
              onClick={() => {
                router.push(showMoreLink);
              }}
              Component='div'
              style={{ color: showMoreColor }}
              size='s16ToS14'
              className='cursor-pointer text-nowrap'
            >
              {/* {`>`} */}
              {isShowMore}
            </Typography>
          )}
        </div>
      )}
      {isHeader && <div className='w-full bg-[#363843] h-[1px]' />}

      {cover && (
        <div style={{ aspectRatio: aspectRatio }} className='aspect-w-16 aspect-h-9 lg:p-2 pb-0 p-[8px]'>
          <div className={`${temp3} border border-[.5px] w-full h-full flex justify-center items-center`}>Image</div>
        </div>
      )}

      {children ? (
        children
      ) : (
        <div className={`flex flex-col text-wrap ${temp1}`}>
          <Typography
            Component='div'
            className={`line-clamp-2 ${isMultiLine ? 'min-h-[50px] xl:min-h-[55px] 2xl:min-h-[60px]' : ''}`}
            //
            isBold
            size='s20ToS16'
            style={{ whiteSpace: 'normal', overflowWrap: 'break-word', color: titleColor, textAlign: titleAlign }}
          >
            {title}
          </Typography>

          <div className={`${isContentReverse ? 'flex-col-reverse' : 'flex-col'} flex`}>
            {isBulletData && (
              <div className='flex flex-col gap-3 2xl:gap-4 mt-[18px] md:mt-5 xl:mt-6'>
                {bulletData.map((item, index) => {
                  return (
                    <Typography
                      bulletNode={<Bullet shadow={false} shadowColor={'#FF6F1E'} bgcolor={'#FF6F1E'} />}
                      key={index}
                      Component='div'
                      // className={`mt-3 lg:mt-4 xl:mt-2 ${styles.description} max-h-[${maxH}px]`}
                      size='s16ToS14'
                      style={{ whiteSpace: 'normal', overflowWrap: 'break-word', maxHeight: `${maxH}px` }}
                    >
                      {item}
                    </Typography>
                  );
                })}
              </div>
            )}
            {content && (
              <Typography
                Component='div'
                className={`mt-3 lg:mt-4 xl:mt-2 ${styles.description} max-h-[${maxH}px]`}
                size='s16ToS14'
                style={{ whiteSpace: 'normal', overflowWrap: 'break-word', maxHeight: `${maxH}px`, color: contentColor }}
              >
                {content}
              </Typography>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

CustomCard.displayName = 'CustomCard';

export default CustomCard;
