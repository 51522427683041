import React from 'react';
import { Typography } from '@/components/Atoms';

export interface SectionHeaderProps {
  title: string;
  subTitle?: string;
}

const SectionHeader: React.FunctionComponent<SectionHeaderProps> = ({ title, subTitle }) => (
  <div className='mb-10 mt-12 lg:mt-20'>
    <Typography className='text-center font-bold text-[22px] lg:text-[50px]' Component={'h2'}>
      {title}
    </Typography>
    {subTitle && (
      <Typography className='text-center font-bold text-[22px] lg:text-[50px] text-[#47FEFF]' Component={'p'}>
        {subTitle}
      </Typography>
    )}
  </div>
);

export default SectionHeader;
