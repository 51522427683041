import React, { useEffect, useState } from 'react';
import api from '@/api/services/index';
import { Header, RoadMapItems } from '@/components/GameRoadMap';

const RoadmapLayout = () => {
  const [roadmap, setRoadmap] = useState();

  useEffect(() => {
    api.getRoadMap().then((res) => {
      setRoadmap(res.data);
    });
  }, []);

  return (
    <div className='w-full flex flex-col items-center mt-10 mb-16 lg:mt-14 lg:mb-20'>
      {/* <div className='max-w-full xl:max-w-[1440px] 2xl:max-w-[1400px] w-full relative px-3 md:px-[42px] lg:px-[36px] xl:px-[65px] '> */}
      <Header />
      <RoadMapItems isHomePage />
      {/* </div> */}
    </div>
    //    <Wrapper>
    //    <RoadmapTokenSale roadmap={roadmap} />
    //  </Wrapper>
  );
};

export default RoadmapLayout;
