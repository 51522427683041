/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import certikSky from '@/public/images/certik-skynet.png';
import certikTop from '@/public/images/certik-top.png';
import watched from '@/public/images/certik-watched.png';
import certik from '@/public/images/certik.png';
import coinSult from '@/public/images/coinsult_full.png';
import cyberScope from '@/public/images/cyberscope.png';
import goldKyc from '@/public/images/gold-kyc.png';
import SectionHeader from '../../Molecules/SectionHeader';
import styles from '../index.module.scss';

export interface AuditedBySectionProps {}

const certikLink = 'https://skynet.certik.com/projects/landrocker';

const images = [
  { src: goldKyc, href: certikLink },
  { src: certikSky, href: certikLink },
  { src: watched, href: certikLink },
  { src: certik, href: certikLink },
  { src: certikTop, href: certikLink },
  { src: coinSult, href: 'https://coinsult.net/projects/landrocker/' },
  { src: cyberScope, href: 'https://www.cyberscope.io/audits/3-lrt' },
];

const AuditedBySection: React.FunctionComponent<AuditedBySectionProps> = () => {
  const { t } = useTranslation('newHome');
  const { pathname } = useRouter();
  const isBuyLrt = pathname !== '/buy-lrt';

  return (
    <div className={classNames({ [styles.gradient_audit_box]: isBuyLrt }, 'px-4 pt-1 md:px-20 lg:px-24 xl:px-44 2xl:px-72 pb-10 lg:pb-20')}>
      <SectionHeader title={t('newHome:auditetBy')} />
      <div className={classNames('grid grid-cols-3 lg:grid-cols-4 grid-rows-2 gap-2 lg:gap-4', styles.container)}>
        {images.map((image, index) => {
          return (
            <div className='mb-4 lg:mb-0 mx-auto' key={index}>
              <Link href={image.href} passHref={true}>
                <a  target='_blank' id='whitepaper-btn'>
                  <div className='hover:scale-105 transition-transform'>
                    <Image placeholder='blur' src={image.src} objectFit='contain' width={207} height={64} alt='white-paper' />
                  </div>
                </a>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AuditedBySection;
