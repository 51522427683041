/* eslint-disable arrow-body-style */
import React from 'react';
import cn from 'classnames';
import styles from './Bullet.module.scss';

interface AdditionalClass {
  wrapper?: string;
  shadow?: string;
  bullet?: string;
}

interface ComponentProps {
  bgcolor?: string;
  shadow?: boolean;
  shadowColor?: string;
  additionalClass?: AdditionalClass;
}

const Bullet: React.FC<ComponentProps> = ({
  bgcolor = '#29D7DF', // Default value
  shadow, // Default value
  shadowColor, // No default value here to check later
  additionalClass,
}) => {
  return (
    <div className={cn(styles.container, additionalClass?.wrapper)}>
      <div
        className={cn(styles.circle, additionalClass?.bullet)}
        style={{
          backgroundColor: bgcolor,
          boxShadow: shadow ? `0px 0px 11px 0px ${shadowColor ? shadowColor : '#29D7DF'}` : '', // Default shadow color
        }}
      />
    </div>
  );
};

export default Bullet;
